import type * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import * as Sentry from '@sentry/react';

import { getLanguageCode } from 'js/lib/language';
import thirdParty from 'js/lib/thirdParty';

import { ARKOSE_DEFAULT_KEY } from 'bundles/authentication/constants';
import localStorageEx from 'bundles/common/utils/localStorageEx';

const STATUS_URL = 'https://status.arkoselabs.com/api/v2/status.json';
export const MAXIMUM_RETRIES = 3;
export const MAXIMUM_TIMEOUT_DURATION = 7500; // Exported for use in unit test

export const USER_CLOSED_CHALLENGE = 'Challenge closed by user';

const makeErrorMessage = (error: string) => `arkose${error}Error`;

// Error List - exported for use in unit test
export const MAX_ENFORCEMENT_RETRIES_ERROR = makeErrorMessage('MaxEnforcementRetries');
export const GENERIC_CLIENT_SIDE_ERROR = makeErrorMessage('GenericClientSide');
export const SCRIPT_LOAD_ERROR = makeErrorMessage('ScriptLoad');
export const ENFORCEMENT_FAILED_ERROR = makeErrorMessage('EnforcementFailed');
export const SCRIPT_CONFIGURATION_ERROR = makeErrorMessage('ScriptConfiguration');
export const KEY_CONFIGURATION_ERROR = makeErrorMessage('KeyConfiguration');
export const ONREADY_NOT_TRIGGERED_ERROR = makeErrorMessage('OnReadyNotTriggered');

type ArkoseEvent = 'onReadyTriggered' | 'enforcementReady';

const ONREADY_EVENT: ArkoseEvent = 'onReadyTriggered';
const ENFORCEMENT_EVENT: ArkoseEvent = 'enforcementReady';

export const getScriptUrl = (key: string) => {
  // Exported for use in unit test
  return `https://coursera-api.arkoselabs.com/v2/${key}/api.js`;
};

type Props = {
  publicKey?: string;
  shouldSkipHook?: boolean;
  onHideCallback?: () => void;
  setCanSwitchArkoseKey?: React.Dispatch<React.SetStateAction<boolean>>;
};

type EnforcementConfig = {
  publicKey?: string;
  language: string;
  onReady: () => void;
  onHide: () => void;
  onCompleted: (response: { token: string }) => void;
  onError: (error?: { error: string }) => void;
};

type Enforcement = {
  setConfig: (config: EnforcementConfig) => void;
  run: () => void;
  reset: () => void;
};

declare global {
  interface Window {
    setupEnforcement?: (enforcement: Enforcement) => void;
  }
}

export type ArkoseBotManager = {
  reset: () => void | undefined;
  getToken: () => Promise<string>;
  setPublicKey: (key: string) => void;
  currentPublicKey?: string;
};

function getLanguage() {
  const code = getLanguageCode();
  return code === 'zh-mo' ? 'zh' : code;
}

/**
 * Custom React hook that manages the integration with Arkose Labs for bot detection.
 * This hook handles loading the Arkose Labs API script, setting up enforcement,
 * retry logic, timeout management, and API health checking.
 *
 * @param {string} publicKey - The Arkose Labs public key required for API integration.
 *                             Different flows may use different public keys.
 * @param {boolean} [shouldSkipHook=false] - The boolean to control whether the hook's logic should be executed.
 * @param {function} onHideCallback Callback invoked when the challenge is closed via the ESC key, the X close button, or after the onCompleted callback.
 *
 * @returns {object} - Returns an object with the following methods:
 *   - `reset`: A method to reset the Arkose Labs enforcement if necessary.
 *   - `getToken`: A method to initiate the enforcement process and return a token when no challenge is needed OR the challenge is completed.
 */
const useArkoseBotManager = ({
  shouldSkipHook = false,
  onHideCallback,
  publicKey = ARKOSE_DEFAULT_KEY,
  setCanSwitchArkoseKey,
}: Props): ArkoseBotManager => {
  const enforcementRef = useRef<Enforcement | null>(null);
  const tokenPromiseRef = useRef<Promise<string>>(new Promise(() => {}));
  const tokenResolverRef = useRef<(token: string) => void>(() => {});
  const tokenRejecterRef = useRef<(error: Error | string) => void>(() => {});
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout>>();
  const onReadyRetryCountRef = useRef(0);
  const enforcementRetryCountRef = useRef(0);
  const errorRef = useRef<Error | null>(null);
  const isOnReadyTriggered = useRef(false);
  const currentPublicKey = useRef(publicKey);

  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  const scriptUrl = getScriptUrl(publicKey);

  // Helper function to create a new token promise
  const createNewTokenPromise = () => {
    tokenPromiseRef.current = new Promise<string>((resolve, reject) => {
      tokenResolverRef.current = resolve;
      tokenRejecterRef.current = reject;
    });
  };

  const fetchArkoseAPIHealthStatus = async () => {
    const response = await fetch(STATUS_URL);
    return response.json();
  };

  const handleFailure = useCallback<(error: string) => void>((error) => {
    errorRef.current = new Error(error);
  }, []);

  const onCompleted = useCallback<(token: string) => void>((token) => {
    tokenResolverRef.current(token);
  }, []);

  const onHide = useCallback<() => void>(() => {
    if (onHideCallback) onHideCallback();
  }, [onHideCallback]);

  const onReady = () => {
    isOnReadyTriggered.current = true;
    clearTimeout(timeoutIdRef.current);
    const event = new Event(ONREADY_EVENT);
    window.dispatchEvent(event);
  };

  const waitForArkoseReady = (event: ArkoseEvent): Promise<void> => {
    let waitTime = 0;
    const startTime = performance.now();
    return new Promise<void>((resolve, reject) => {
      Sentry.startSpanManual({ name: `arkose.${event}.waitForReady`, sampled: false }, (span) => {
        // Set up a timeout to reject the promise if Arkose doesn't become ready in time
        const timeout = setTimeout(() => {
          span?.end();
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          window.removeEventListener(event, handleReady);
          reject(new Error(`Timeout waiting for Arkose ${event} event`));
        }, MAXIMUM_TIMEOUT_DURATION);

        const handleReady = () => {
          const endTime = performance.now();
          waitTime = endTime - startTime;
          Sentry.captureMessage(`Arkose Bot Manager: ${event} latency after clicking the button`, {
            extra: {
              latency: waitTime,
            },
          });
          span?.end();

          resolve();
          window.removeEventListener(event, handleReady);
          clearTimeout(timeout);
        };

        window.addEventListener(event, handleReady);
      });
    });
  };

  const onError = useCallback<(error?: string) => void>(
    async (error) => {
      let isHealthyStatus = false;

      try {
        const { status } = await fetchArkoseAPIHealthStatus();
        isHealthyStatus = status?.indicator === 'none';

        if (!isHealthyStatus) {
          Sentry.captureException(`useArkoseBotManager Unhealthy Status`, { extra: status?.indicator });
        }
      } catch (healthStatusError) {
        Sentry.captureException(`useArkoseBotManager Health Status Check Failed`, { extra: healthStatusError });
      }

      if (isHealthyStatus && enforcementRetryCountRef.current < MAXIMUM_RETRIES) {
        enforcementRef.current?.reset();
        enforcementRetryCountRef.current += 1;
        // To ensure the enforcement has been successfully reset, we need to set a timeout here
        setTimeout(() => {
          enforcementRef.current?.run();
        }, 500);
        return;
      }

      if (error && enforcementRetryCountRef.current === MAXIMUM_RETRIES) {
        handleFailure(MAX_ENFORCEMENT_RETRIES_ERROR);
        return;
      }
      if (error) {
        handleFailure(GENERIC_CLIENT_SIDE_ERROR);
      }
    },
    [handleFailure]
  );

  const handleScriptSuccess = () => {
    setIsScriptLoaded(true);
  };

  const loadScript = useCallback<() => void>(async () => {
    thirdParty.removeScript(scriptUrl);

    try {
      await thirdParty.loadScript({
        url: scriptUrl,
        attributes: {
          defer: true,
          'data-callback': 'setupEnforcement',
        },
        retries: MAXIMUM_RETRIES,
        timeout: MAXIMUM_TIMEOUT_DURATION,
      });

      handleScriptSuccess();
    } catch {
      handleFailure(SCRIPT_LOAD_ERROR);
    }
  }, [handleFailure, scriptUrl]);

  const enforcementConfig: EnforcementConfig = useMemo(() => {
    const dataBlob = localStorageEx.getItem('arkoseDataBlob', String, undefined);

    return {
      ...(dataBlob ? { data: { blob: dataBlob } } : {}),
      language: getLanguage(),
      onReady: () => onReady(),
      onHide: () => onHide(),
      onCompleted: (response) => onCompleted(response.token),
      onError: (response) => onError(response?.error),
    };
  }, [onCompleted, onError, onHide]);

  // Function to setup enforcement after the Arkose script loads
  const setupEnforcement = useCallback<(enforcement: Enforcement) => void>(
    (enforcement) => {
      enforcementRef.current = enforcement;
      if (setCanSwitchArkoseKey) setCanSwitchArkoseKey(true);
      enforcementRef.current.setConfig(enforcementConfig);

      const event = new Event(ENFORCEMENT_EVENT);
      window.dispatchEvent(event);
    },
    [enforcementConfig, setCanSwitchArkoseKey]
  );

  useEffect(() => {
    if (shouldSkipHook) return;

    // Try loading the script
    loadScript();

    // Set window setupEnforcement function
    window.setupEnforcement = setupEnforcement;

    createNewTokenPromise();
  }, [loadScript, setupEnforcement, shouldSkipHook]);

  // Timeout check for onReady callback,
  // if onReady is not triggered when timeout, try reset the enforcement object
  const setOnReadyTimeout = useCallback<() => void>(() => {
    const tryReset = () => {
      if (onReadyRetryCountRef.current >= MAXIMUM_RETRIES) {
        handleFailure(SCRIPT_CONFIGURATION_ERROR);
      } else if (isScriptLoaded) {
        onReadyRetryCountRef.current += 1;
        enforcementRef.current?.reset();

        timeoutIdRef.current = setTimeout(tryReset, MAXIMUM_TIMEOUT_DURATION);
      }
    };

    timeoutIdRef.current = setTimeout(tryReset, MAXIMUM_TIMEOUT_DURATION);
  }, [handleFailure, isScriptLoaded]);

  useEffect(() => {
    if (shouldSkipHook) return () => {};

    setOnReadyTimeout();

    return () => {
      clearTimeout(timeoutIdRef.current);
    };
  }, [setOnReadyTimeout, shouldSkipHook]);

  // Separate effect for unmount cleanup
  useEffect(() => {
    return () => {
      if (shouldSkipHook) return;

      if (window.setupEnforcement) {
        delete window.setupEnforcement;
      }
      thirdParty.removeScript(scriptUrl);
    };
  }, [scriptUrl, shouldSkipHook]);

  if (shouldSkipHook) {
    return {
      reset: () => {},
      getToken: () => Promise.reject(new Error('Hook skipped')),
      setPublicKey: () => {},
    };
  }

  return {
    currentPublicKey: currentPublicKey.current,
    // Expose a method to reset the enforcement
    reset: () => {
      if (!enforcementRef.current) {
        Sentry.captureException('useArkoseBotManager error', {
          extra: {
            error: 'Enforcement was not configured successfully when calling reset(), something unexpected happended',
          },
        });
      } else {
        createNewTokenPromise();
        enforcementRef.current.reset();
      }
    },
    getToken: async () => {
      // Should return early if there is an error before fetching the token
      // Except the ENFORCEMENT_FAILED_ERROR and ONREADY_NOT_TRIGGERED_ERROR, since they might get ready on the next fetch
      if (
        errorRef.current &&
        ![ENFORCEMENT_FAILED_ERROR, ONREADY_NOT_TRIGGERED_ERROR].includes(errorRef.current.message)
      ) {
        tokenRejecterRef.current(errorRef.current);
        return tokenPromiseRef.current;
      }
      createNewTokenPromise();

      if (!enforcementRef.current) {
        try {
          await waitForArkoseReady(ENFORCEMENT_EVENT);
        } catch {
          handleFailure(ENFORCEMENT_FAILED_ERROR);
          tokenRejecterRef.current(new Error(ENFORCEMENT_FAILED_ERROR));
          return tokenPromiseRef.current;
        }
      }

      // Re-check enforcementRef.current since it may have been set asynchronously
      if (!enforcementRef.current) {
        handleFailure(ENFORCEMENT_FAILED_ERROR);
        tokenRejecterRef.current(new Error(ENFORCEMENT_FAILED_ERROR));
        return tokenPromiseRef.current;
      }

      if (!isOnReadyTriggered.current) {
        try {
          await waitForArkoseReady(ONREADY_EVENT);
        } catch {
          handleFailure(ONREADY_NOT_TRIGGERED_ERROR);
          tokenRejecterRef.current(new Error(ONREADY_NOT_TRIGGERED_ERROR));
          return tokenPromiseRef.current;
        }
      }

      enforcementRef.current.run();

      // Consider implementing a timeout for the promise to prevent it from remaining pending due to potential Arkose API failures.
      return tokenPromiseRef.current;
    },
    setPublicKey: (key) => {
      if (enforcementRef.current) {
        isOnReadyTriggered.current = false;
        clearTimeout(timeoutIdRef.current);
        onReadyRetryCountRef.current = 0;
        setOnReadyTimeout();
        enforcementRef.current.setConfig({
          ...enforcementConfig,
          publicKey: key,
        });
        currentPublicKey.current = key;
      } else {
        handleFailure(KEY_CONFIGURATION_ERROR);
      }
    },
  };
};

export default useArkoseBotManager;
