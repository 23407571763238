import * as Sentry from '@sentry/react';
import type { Extras } from '@sentry/types';

type Message = {
  error: Error;
  source?: string;
  extra?: Extras;
  level?: Sentry.SeverityLevel;
};

/**
 * Capture messages in Sentry with the Coach prefix which enables us to filter for errors related to Coach
 * which we can target for slack alerts.
 */
export const captureMessageInSentry = ({ error, source = '', extra = {}, level = 'error' }: Message) => {
  const sourceString = source ? `[${source}]` : '';

  try {
    Sentry.captureMessage(`[AiCourseCoach]: ${sourceString} ${error.toString()}`, {
      level,
      extra: {
        error,
        ...extra,
      },
    });
  } catch {
    // eslint-disable-next-line no-console
    console.error('[AiCourseCoach]: Unable to capture this exception in Sentry', { error });
  }
};
