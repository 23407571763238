/* @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { LONG_DATE_ONLY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { Grid, ProductCard, Typography2, breakpoints } from '@coursera/cds-core';
import { useTracker } from '@coursera/event-pulse/react';

import type { Maybe } from 'bundles/cms/types';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import type { ArticleItemFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';
import ShowMoreSection from 'bundles/premium-hub/components/shared/ShowMoreSection';
import useTrackLinks from 'bundles/premium-hub/hooks/useTrackLinks';
import { filterNullOrUndefined } from 'bundles/premium-hub/utils';
import useEstimatedReadTimeData from 'bundles/unified-seo-content-common/hooks/useEstimatedReadTimeData';

import _t from 'i18n!nls/premium-hub';

import 'css!bundles/premium-hub/components/__styles__/shared/InfoCard';

type Props = {
  articlesHeader?: Maybe<string>;
  articles?: Array<Maybe<ArticleItemFragment>>;
};

type ArticleCardProps = {
  article: ArticleItemFragment;
};

const styles = {
  titleLink: css`
    display: block;
  `,
  productCard: css`
    .cds-ProductCard-base {
      ${breakpoints.down('sm')} {
        max-width: 100% !important;
      }
    }
  `,
};

const ArticleCard = ({ article: { title, slug, intro, sys } }: ArticleCardProps) => {
  const trackV3 = useTracker();

  const { estimatedReadTimeLabel } = useEstimatedReadTimeData({ slug: slug ?? '' });

  const linkURL = `/articles/${slug}`;
  const linkName = title || _t('Coursera Article');

  return (
    <Grid item xs={12} sm={6} md={3} css={styles.productCard}>
      <ProductCard
        variant="grid"
        productType="article"
        aria-label={`${title}. ${intro}. ${estimatedReadTimeLabel ?? ''}`}
        title={{
          name: linkName,
          linkProps: {
            href: linkURL,
            target: '_blank',
            rel: 'noopener noreferrer',
            component: TrackedLink2,
            css: styles.titleLink,
          },
          customLinkProps: {
            trackingName: 'top_related_article',
            withVisibilityTracking: true,
            requireFullyVisible: false,
            data: {
              product: slug,
            },
          },
        }}
        body={
          <Typography2 variant="bodySecondary" color="supportText" component="p">
            {intro}
          </Typography2>
        }
        footer={
          <>
            <Typography2 variant="bodySecondary" color="supportText" component="p">
              {sys?.publishedAt &&
                `${_t('Last updated on #{time}', {
                  time: formatDateTimeDisplay(sys.publishedAt, LONG_DATE_ONLY_DISPLAY),
                })}`}
            </Typography2>
            <Typography2 variant="bodySecondary" color="supportText" component="p">
              {_t('Article')}
              {estimatedReadTimeLabel && ` · ${estimatedReadTimeLabel}`}
            </Typography2>
          </>
        }
        onClick={() =>
          trackV3('click_article_card', {
            article: {
              articleSlug: slug ?? '',
              articleTitle: title ?? '',
            },
          })
        }
      />
    </Grid>
  );
};

const ArticlesSection = ({ articlesHeader, articles }: Props) => {
  const onViewAllLinkClick = useTrackLinks('explore_articles', 'article_list');

  if (!articles || articles?.length === 0) {
    return null;
  }

  return (
    <div>
      <ShowMoreSection
        translatedEntitiesType={_t('Articles')}
        viewAllLinkHref="/articles"
        header={articlesHeader ?? _t('Gain helpful insight on degree-related topics')}
        items={articles.filter(filterNullOrUndefined).map((article) => (
          <ArticleCard key={article?.slug} article={article} />
        ))}
        backgroundColor="var(--cds-color-grey-25)"
        expandToItemsListLength={true}
        onViewAllLinkClick={onViewAllLinkClick}
      />
    </div>
  );
};

export default React.memo(ArticlesSection);
