import { once } from 'lodash';

import Retracked from 'js/app/retracked';
import { getLanguageCode } from 'js/lib/language';
import type { useRetracked } from 'js/lib/retracked';
import thirdParty from 'js/lib/thirdParty';

import type { RecaptchaAction } from 'bundles/authentication/constants';

// We need this, @types/recaptcha doesn't support enterprise version
// Enterprise version is the same as regular version in the enterprise field
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReCaptchaV2 {
    interface ReCaptcha {
      enterprise: ReCaptcha;
    }
  }

  interface Window {
    reCAPTCHAAsyncInit?: () => void;
  }
}

type EventData = {
  namespace: {
    app: string;
    page: string;
  };
};

export type Options = {
  _eventData?: EventData;
  checkbox?: boolean;
  invisible?: boolean;
  onResponse?: (token: string) => void;
  trackingFn?: ReturnType<typeof useRetracked>;
  action?: RecaptchaAction;
};

export type ReCAPTCHA = {
  getResponse: () => Promise<string>;
  reset: () => void;
};

// First two keys are managed on reCAPTCHA Enterprise page at console.cloud.google.com
const CHECKBOX_KEY = '6Lcd1dYZAAAAALN-IJtRkgJFNmWR-n5ll2SqQSVc';
const INVISIBLE_KEY = '6LcA5NcZAAAAAFwyAhbdepM7RxI34-pODRbqaLLq';
// See https://developers.google.com/recaptcha/docs/faq#id-like-to-run-automated-tests-with-recaptcha.-what-should-i-do
const TEST_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

function getLanguage() {
  const code = getLanguageCode();

  return code === 'zh-mo' ? 'zh' : code;
}

const getRecaptchaApi = once(async () => {
  await new Promise<void>((ok) => {
    window.reCAPTCHAAsyncInit = ok;
  });
  return window.grecaptcha.enterprise;
});

async function loadScript() {
  try {
    return await thirdParty.loadScript({
      url: `https://www.google.com/recaptcha/enterprise.js?onload=reCAPTCHAAsyncInit&render=explicit&hl=${getLanguage()}`,
    });
  } catch {
    return thirdParty.loadScript({
      url: `https://www.recaptcha.net/recaptcha/enterprise.js?onload=reCAPTCHAAsyncInit&render=explicit&hl=${getLanguage()}`,
    });
  }
}

const isTestingEnvironment = () => {
  const UA = (typeof window !== 'undefined' && navigator.userAgent) || '';
  return UA.toLowerCase().includes('catchpoint');
};

const getSiteKey = ({ shouldUseInvisibleKey }: { shouldUseInvisibleKey: boolean }) => {
  if (isTestingEnvironment()) {
    return TEST_KEY;
  } else {
    return shouldUseInvisibleKey ? INVISIBLE_KEY : CHECKBOX_KEY;
  }
};

/**
 * Provides a reCAPTCHA wrapper. It will handle the load and creation of the reCAPTCHA UI.
 *
 * @param {(string|HTMLElement)} container - Element or element id where the reCAPTCHA checkbox/challenge will be shown.
 * @param {Object} [options] - Configurable options
 * @param {Object} [options._eventData] - Context data for the eventing system
 * @param {boolean} [options.checkbox] - Enables checkbox mode
 * @param {boolean} [options.invisible=true] - Enables invisible mode
 * @param {Function} [options.trackingFn] -  the tracking function from useRetracked to track data, so you don't need to pass _eventData
 * @param {string} [options.action] - Action (login / signup) to be used by reCAPTCHA
 */
function reCAPTCHA(
  container: string | HTMLElement,
  { _eventData, checkbox, invisible = !checkbox, onResponse = () => undefined, trackingFn, action }: Options = {
    checkbox: false,
    invisible: true,
  }
): ReCAPTCHA {
  getRecaptchaApi();
  loadScript();

  let id: number;
  let reject: ((reason: string) => void) | null = null;
  let resolve: ((value: string) => void) | null = null;

  const sitekey = getSiteKey({ shouldUseInvisibleKey: invisible });

  (async () => {
    const grecaptcha = await getRecaptchaApi();

    id = grecaptcha.render(container, {
      // This returns the token
      callback: (token) => {
        resolve?.(token);
        onResponse(token);
      },
      // This is recommended in the documentation
      'error-callback': () => grecaptcha.reset(id),
      // There is no specific error this could fail, so at least we will know that something failed
      'expired-callback': () => reject?.('Unknown Error'),
      sitekey,
      size: invisible ? 'invisible' : 'normal',
      /* @ts-expect-error action only exists for enterprise, not v2 */
      action,
    });
  })();

  return {
    /**
     * Must be called after a token is validated by the server. This avoids replay attack.
     */
    async reset(): Promise<void> {
      const grecaptcha = await getRecaptchaApi();

      // id might be 0
      if (id === undefined || !grecaptcha) {
        return;
      }

      grecaptcha.reset(id);
    },

    /**
     * Provides a reCAPTCHA token that needs to be validated by the server.
     *
     * @return {Promise} Returns a promise that contains the token or in case of failure returns a reason code.
     */
    async getResponse(): Promise<string> {
      const grecaptcha = await getRecaptchaApi();

      return new Promise((ok, ko) => {
        resolve = ok;
        reject = ko;

        const response = grecaptcha.getResponse(id);

        if (response || checkbox) {
          ok(response);
        } else if (invisible) {
          grecaptcha.execute(id);
        }

        if (!isTestingEnvironment()) {
          if (trackingFn && typeof trackingFn === 'function') {
            trackingFn({ trackingData: {}, trackingName: 'recaptcha_executed', action: 'show' });
            trackingFn({ trackingData: {}, trackingName: 'recaptcha_enterprise_executed', action: 'show' });
          } else if (_eventData?.namespace) {
            Retracked.trackComponent(_eventData, {}, 'recaptcha_executed', 'show');
            // Only trigger this event until we figure out what's going on with UNKNOWN reason codes.
            Retracked.trackComponent(_eventData, {}, 'recaptcha_enterprise_executed', 'show');
          }
        }
      });
    },
  };
}

export default reCAPTCHA;
