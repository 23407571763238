/* @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import type { ReactNode } from 'react';
import { useState } from 'react';
import * as React from 'react';
import { Element as ReactScrollElement, scroller } from 'react-scroll';

import { Button, Grid, Link, Typography, Typography2, breakpoints } from '@coursera/cds-core';
import { useTracker } from '@coursera/event-pulse/react';

import TrackedButton from 'bundles/page/components/TrackedButton';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import Container from 'bundles/premium-hub/components/shared/Container';

import _t from 'i18n!nls/premium-hub';

import 'css!bundles/premium-hub/components/__styles__/shared/InfoCard';

type Props = {
  header?: string;
  description?: string;
  translatedEntitiesType: string;
  viewAllLinkHref?: string;
  items?: ReactNode[];
  backgroundColor?: string;
  expandToItemsListLength?: boolean;
  shrunkNum?: number;
  expandedNum?: number;
  carouselId?: string;
  overriddenButton?: React.ReactNode;
  onViewAllLinkClick?: (e: React.MouseEvent) => void;
};

const DEFAULT_SHRUNK_NUM = 4;
const DEFAULT_EXPANDED_NUM = 8;

const styles = {
  container: (backgroundColor?: string) => css`
    margin: ${backgroundColor ? 'none' : 'var(--cds-spacing-600) 0'};
    padding: ${backgroundColor ? 'var(--cds-spacing-600) 0' : 'none'};
    background-color: ${backgroundColor ?? 'none'};
    ${breakpoints.down('sm')} {
      margin: ${backgroundColor ? 'none' : 'var(--cds-spacing-200) 0'};
      padding: ${backgroundColor ? 'var(--cds-spacing-200) 0' : 'none'};
    }
  `,
  header: css`
    margin-bottom: var(--cds-spacing-200);
  `,
  titleLink: css`
    display: block;
  `,
  productCard: css`
    .cds-ProductCard-base {
      ${breakpoints.down('sm')} {
        max-width: 100% !important;
      }
    }
  `,
  showAllButtonContainer: css`
    display: flex;
    justify-content: left;
    margin-top: var(--cds-spacing-400);
  `,
};

const ShowMoreSection = ({
  header,
  description,
  translatedEntitiesType,
  viewAllLinkHref,
  items,
  backgroundColor,
  expandToItemsListLength,
  shrunkNum,
  expandedNum,
  carouselId,
  onViewAllLinkClick,
  overriddenButton,
}: Props) => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const trackV3 = useTracker();

  if (!items) return null;

  const numChildren = items.length;

  if (numChildren < 1) {
    return null;
  }

  const entityString = translatedEntitiesType.toLowerCase();
  const scrollAnchorName = `showMore${entityString}ScrollAnchor`;
  const shrunkNumToShow = shrunkNum ?? DEFAULT_SHRUNK_NUM;
  const expandedNumToShow = expandToItemsListLength ? items.length : expandedNum ?? DEFAULT_EXPANDED_NUM;

  return (
    <div css={styles.container(backgroundColor)}>
      <Container>
        <ReactScrollElement name={scrollAnchorName} />
        <Grid container justifyContent="space-between" alignItems="center" css={styles.header}>
          {header && (
            <Grid item>
              <Typography component="h2" variant="h1semibold">
                {header}
              </Typography>
            </Grid>
          )}
          {viewAllLinkHref && (
            <Grid item>
              <Link
                trackingName="view_all_link"
                href={viewAllLinkHref}
                aria-label={_t('Explore all #{entities}', { entities: entityString })}
                typographyVariant="subtitleMedium"
                variant="quiet"
                component={TrackedLink2}
                target="_blank"
                onClick={onViewAllLinkClick}
              >
                {_t('Explore all #{entities}', { entities: entityString })}
              </Link>
            </Grid>
          )}
        </Grid>

        {description && (
          <Typography2
            component="p"
            variant="bodyPrimary"
            css={css`
              margin-bottom: var(--cds-spacing-200);
            `}
          >
            {description}
          </Typography2>
        )}

        <Grid container spacing={24}>
          {items.slice(0, showAll ? expandedNumToShow : shrunkNumToShow)}
        </Grid>

        {!overriddenButton && numChildren > shrunkNumToShow && (
          <div css={styles.showAllButtonContainer} data-testid="showall-button-container">
            <Button
              component={TrackedButton}
              variant="secondary"
              onClick={() => {
                setShowAll((current) => !current);
                if (!showAll) return;
                scroller.scrollTo(scrollAnchorName, {
                  smooth: true,
                  offset: -125,
                });
                if (carouselId)
                  trackV3('expand_carousel', {
                    carousel: {
                      id: carouselId,
                    },
                  });
              }}
              data-testid="show-all-button"
              trackingName="premium_hub_showmore_showless"
              withVisibilityTracking={false}
              requireFullyVisible={false}
            >
              {showAll ? _t('Show fewer') : _t('Show more', { numItems: numChildren })}
            </Button>
          </div>
        )}
        {overriddenButton && (
          <div css={styles.showAllButtonContainer} data-testid="overridden-button-container">
            {overriddenButton}
          </div>
        )}
      </Container>
    </div>
  );
};

export default ShowMoreSection;
