/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { Fragment, useMemo } from 'react';

import { Divider, Typography2, breakpoints, typography2 } from '@coursera/cds-core';
import { useTracker } from '@coursera/event-pulse/react';

import { CourseraPlusLink } from 'bundles/megamenu/components/CourseraPlusLink';
import SimpleMenuItem from 'bundles/megamenu/components/goal-item/SimpleMenuItem';
import type { SimplifiedMegamenuSectionIds } from 'bundles/megamenu/constants/simplifiedMegaMenuContent';
import { simplifiedMegaMenuContent } from 'bundles/megamenu/constants/simplifiedMegaMenuContent';
import { isInNewMegamenuVariantB, isInNewMegamenuVariantC } from 'bundles/megamenu/utils/experimentUtils';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedA } from 'bundles/page/components/TrackedLink2';

import _t from 'i18n!nls/megamenu';

export type Props = {
  isMobile: boolean;
  noMobileHeaderPadding?: boolean;
  filterSection?: SimplifiedMegamenuSectionIds;
  shouldShowCourseraPlusButton?: boolean;
};

const maxWidth = '1200px';

const style = {
  grid: css`
    column-count: 4;
    width: 100%;
    height: 100%;
    padding: var(--cds-spacing-200);
    line-height: var(--cds-line-height-350);
    justify-content: space-between;
    column-gap: var(--cds-spacing-300);
    max-width: ${maxWidth};
    margin: auto;

    > *:not(:last-child) {
      break-inside: avoid;
      page-break-inside: avoid;
    }
  `,
  mobileGrid: css`
    display: flex;
    flex-direction: column;
    line-height: var(--cds-line-height-400);
    font-size: 16px;
  `,
  mobileGridPadding: css`
    padding-top: 98px;
  `,
  explicitLink: css`
    text-decoration: underline;
    color: var(--cds-color-neutral-primary);

    &:hover,
    &:focus {
      text-decoration: none;
    }
  `,
  viewAllLink: css`
    ${typography2.bodySecondary}

    text-align: left;
    vertical-align: middle;
    display: inline-block;
    white-space: normal;

    ${breakpoints.down('sm')} {
      ${typography2.bodyPrimary}
    }
  `,
  removeExtraMargin: css`
    margin-top: 0;
  `,
  removeExtraPadding: css`
    padding-right: 0;
  `,
  sectionContainer: css`
    height: 100%;
    width: fit-content;
    margin-bottom: var(--cds-spacing-300);
  `,
  mobileSectionContainer: css`
    height: 100%;
    width: 100%;
  `,
  sectionTitle: css`
    ${typography2.subtitleMedium}
    display: block;
    margin-bottom: var(--cds-spacing-100);
    width: 100%;
    height: fit-content;
    text-wrap: wrap;
  `,
  mobileSectionTitle: css`
    ${typography2.bodyPrimary}
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 12px;
    width: 100%;
    padding-top: 20px;
  `,
  subsection: css`
    list-style: none;
    padding: 0;
    width: max-content;
    max-height: 70%;
    column-gap: var(--cds-spacing-400);
    margin-bottom: 0;
  `,

  item: css`
    ${typography2.bodySecondary}
    flex: 0 0 auto;
    width: fit-content;
    box-sizing: border-box;
    padding-bottom: var(--cds-spacing-50);

    ${breakpoints.down('sm')} {
      ${typography2.bodyPrimary}
      padding-bottom: var(--cds-spacing-200);
    }
  `,
  headerItem: css`
    font-weight: 600;
    line-height: 20px;
  `,
  headerMargin: css`
    margin-bottom: 18px;
  `,
  footer: css`
    width: 100%;
    max-width: ${maxWidth};
    margin: auto;
    padding: var(--cds-spacing-200);
  `,
  footerContent: css`
    ${typography2.bodySecondary}
    padding: var(--cds-spacing-300) 0 var(--cds-spacing-200);
  `,
  footerLinks: css`
    margin-left: 10px;
    display: inline-flex;
    gap: var(--cds-spacing-100);
    align-items: baseline;
  `,
};

/**
 * Renders a simplified megamenu section with support for both mobile and desktop layouts.
 *
 * This component displays megamenu content in a structured format, with sections that can contain
 * nested menu items and links. It provides different layouts and styling for mobile and desktop views,
 * supports tracking of user interactions, and can optionally show promotional content in a footer section.
 *
 * The component can render either all available menu sections or filter to show just one specific section.
 * Each section can have a title (optionally linkable), a list of menu items (which can themselves have nested items),
 * and a "view all" footer link. Menu items can be either simple links or grouped categories with child links.
 */
export const SimplifiedMegaMenuSection = (props: Props) => {
  const { isMobile, filterSection, noMobileHeaderPadding = false, shouldShowCourseraPlusButton } = props;
  const megaMenuContent = useMemo(() => {
    if (filterSection) {
      return simplifiedMegaMenuContent().filter((section) => section.name === filterSection);
    } else {
      return simplifiedMegaMenuContent();
    }
  }, [filterSection]);
  const track = useTracker();
  const showFooterSection =
    !isMobile && (isInNewMegamenuVariantB() || (isInNewMegamenuVariantC() && shouldShowCourseraPlusButton));

  return (
    <div aria-label={isMobile ? _t('Menu content') : ''}>
      <div
        data-testid="simplified-megaMenu-section"
        css={[isMobile ? style.mobileGrid : style.grid, !noMobileHeaderPadding && isMobile && style.mobileGridPadding]}
      >
        {megaMenuContent.map((section, sectionIndex) => {
          const title = (
            <Typography2
              variant="subtitleMedium"
              component="p"
              css={isMobile ? style.mobileSectionTitle : style.sectionTitle}
            >
              {section.label}
            </Typography2>
          );

          return (
            <TrackedDiv
              className="mobile-submenu-header"
              tabIndex={isMobile ? 0 : -1}
              key={section.name}
              css={[
                isMobile ? style.mobileSectionContainer : style.sectionContainer,
                sectionIndex === megaMenuContent.length - 1 && style.removeExtraPadding,
              ]}
              trackingName="mega_menu_section"
              trackClicks={false}
              trackMouseEnters={true}
              data={{
                sectionName: section.name,
                sectionType: section.type,
                viewAllText: section.footer?.label,
                viewAllUrl: section.footer?.href,
                sectionItemsLength: section.children?.length,
              }}
            >
              {section.href && !isMobile ? (
                <TrackedA
                  tabIndex={0}
                  href={section.href}
                  trackingName="section_header"
                  data={{
                    sectionName: section?.label,
                    sectionType: section.sectionType,
                    domainSlug: section.name,
                    sectionItemsLength: section?.children?.length || 0,
                  }}
                  onClick={() => {
                    track('click_megamenu_item', {
                      megamenuItemLink: section.href,
                      megamenuItemName: 'section_header',
                      megamenuSection: section?.name ?? '',
                      megamenuItemType: 'other',
                    });
                  }}
                >
                  {title}
                </TrackedA>
              ) : (
                title
              )}

              {section?.children && (
                <ul css={[style.subsection]}>
                  {section.children.map((menuItem, index) => {
                    return (
                      <li css={style.item} key={`${menuItem.name}-${section.name}`}>
                        {menuItem.type === 'link' ? (
                          <SimpleMenuItem
                            item={{
                              ...menuItem,
                              name: menuItem.label,
                              id: menuItem.name,
                              url: menuItem.href,
                              type: section.sectionType,
                            }}
                            isMobile={isMobile}
                            tabName={menuItem.name}
                            sectionName={menuItem.name}
                            tabId={section.name}
                            hideAnchorColor={true}
                            isSimplifiedMegaMenu={true}
                          />
                        ) : (
                          <span css={style.headerItem}>{menuItem.label}</span>
                        )}
                        {menuItem?.children && (
                          <ul css={[style.subsection, index + 1 !== section.children?.length && style.headerMargin]}>
                            {menuItem.children.map((item) => {
                              return (
                                <li css={style.item} key={`${item.name}-${menuItem.name}`}>
                                  <SimpleMenuItem
                                    item={{
                                      ...item,
                                      name: item.label,
                                      id: item.name,
                                      url: item.href,
                                      type: section.sectionType,
                                    }}
                                    isMobile={isMobile}
                                    tabName={item.name}
                                    tabId={section.name}
                                    sectionName={menuItem.name}
                                    hideAnchorColor={true}
                                    isSimplifiedMegaMenu={true}
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
              {section.footer?.href && (
                <TrackedA
                  tabIndex={0}
                  href={section.footer?.href}
                  trackingName={isMobile ? 'mobile_section_view_all_button' : 'section_view_all_button'}
                  css={[style.viewAllLink, style.explicitLink, !section.children?.length && style.removeExtraMargin]}
                  data={{
                    sectionName: section?.label,
                    sectionType: section.sectionType,
                    domainSlug: section.name,
                    viewAllText: section.footer?.label,
                    viewAllUrl: section.footer?.href,
                    sectionItemsLength: section?.children?.length || 0,
                  }}
                  onClick={() => {
                    track('click_megamenu_item', {
                      megamenuItemLink: section.footer?.href ?? '',
                      megamenuItemName: section?.footer?.name ?? '',
                      megamenuSection: section?.name ?? '',
                      megamenuItemType: 'view_all_link',
                    });
                  }}
                >
                  {section.footer?.label}
                </TrackedA>
              )}
            </TrackedDiv>
          );
        })}
      </div>
      {/* bottom of megamenu - footer */}
      {showFooterSection && (
        <div css={style.footer}>
          <Divider color="light" />
          <div css={style.footerContent}>
            {_t('Not sure where to begin?')}

            <span css={style.footerLinks}>
              {isInNewMegamenuVariantB() && (
                <Fragment>
                  <TrackedA
                    css={style.explicitLink}
                    href="/courses?query=free"
                    tabIndex={0}
                    onClick={() => {
                      track('click_megamenu_item', {
                        megamenuItemLink: '/courses?query=free',
                        megamenuItemName: 'browse-free-courses',
                        megamenuSection: 'footer',
                        megamenuItemType: 'other',
                      });
                    }}
                  >
                    {_t('Browse free courses')}
                  </TrackedA>
                  {shouldShowCourseraPlusButton && _t('or')}
                </Fragment>
              )}

              {shouldShowCourseraPlusButton && <CourseraPlusLink />}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SimplifiedMegaMenuSection;
