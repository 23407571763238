import isString from 'lodash/isString';
import Q from 'q';

import memoize from 'js/lib/memoize';
import path from 'js/lib/path';

import api from 'pages/auth/common/api/api';

import type { UserEmail } from '@coursera/grpc-types-useremails/coursera/proto/useremails/v1/email_address';
import type { InsertUserEmailRequest } from '@coursera/grpc-types-useremails/coursera/proto/useremails/v1/user_emails_api';

import constants from 'bundles/third-party-auth/constants';
import { type CheckForcedSsoLoginResponse } from 'bundles/third-party-auth/types/responseTypes';

const _apiCall = function (method: keyof typeof api, ...args: $TSFixMe) {
  // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
  return Q(api[method](...args))
    .then((response) => {
      try {
        return isString(response) ? JSON.parse(response) : response;
      } catch {
        return response;
      }
    })
    .catch((xhr) => {
      try {
        return Q.reject(JSON.parse(xhr.responseText));
      } catch {
        return Q.reject({ code: xhr.responseText });
      }
    });
};

const exported = {
  list: memoize(function () {
    return _apiCall('get', constants.naptimeResource);
  }),

  login(data: $TSFixMe): Q.Promise<{ isRegistration: boolean; userId: number }> {
    return _apiCall('post', constants.authApi, { data }) as $TSFixMe;
  },

  link(data: $TSFixMe) {
    return _apiCall('post', constants.linkApi, { data });
  },

  delink(id: $TSFixMe, data: $TSFixMe) {
    const apiPath = path.join(constants.naptimeResource, id);
    return _apiCall('delete', apiPath, { data });
  },

  addAlternateEmail(data: InsertUserEmailRequest) {
    const apiPath = path.join(constants.userEmailsApi, 'InsertUserEmail');
    return _apiCall('post', apiPath, { data });
  },

  findIdFromEmail(email: string): Q.Promise<{ userEmail: UserEmail }> {
    const apiPath = path.join(constants.userEmailsApi, 'GetUserEmail');
    const data = { emailAddress: email };
    return _apiCall('post', apiPath, { data }) as $TSFixMe;
  },

  updateProfileName(userId: $TSFixMe, fullName: string) {
    const apiPath = path.join(constants.profilesApi, userId);
    const data = {
      userId,
      externalId: '',
      fullName,
    };

    return _apiCall('put', apiPath, { data });
  },

  checkForcedSsoLogin(email: string, returnTo?: string) {
    const data = { email, returnTo: returnTo || '/' };
    const apiPath = constants.checkForcedSsoLoginApi;
    return _apiCall('post', apiPath, { data }) as unknown as Q.Promise<CheckForcedSsoLoginResponse>;
  },
};

export default exported;

export const { list, login, link, delink, addAlternateEmail, findIdFromEmail, checkForcedSsoLogin } = exported;
