/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useRetracked } from 'js/lib/retracked';

import { Button, Dialog, Search, Typography2, VisuallyHidden } from '@coursera/cds-core';
import { useTracker } from '@coursera/event-pulse/react';

import useDebounce from 'bundles/common/hooks/useDebounce';
import SearchFilterItem from 'bundles/search-common/components/filters/SearchFilterItem';
import type { EventingData } from 'bundles/search-common/components/filters/SearchFilterItem';
import type { SearchFilterOption } from 'bundles/search-common/types';
import { removeWhiteSpace } from 'bundles/search-common/utils/utils';

import _t from 'i18n!nls/search-common';

type Props = {
  isModalOpen: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  attribute: string;
  name: string;
  items: SearchFilterOption[];
  clearFilters: () => void;
  eventingData: EventingData;
  setFiltersByFacet?: (facetName: string, filters: string[]) => void;
};

const styles = {
  modalCheckboxGroup: css`
    legend {
      border-bottom: none;
    }

    label {
      margin-bottom: 0;

      .filter-label-count {
        color: var(--cds-color-grey-600);
      }
    }

    display: grid;
    overflow-y: auto;
    height: 240px;
    grid-template-columns: auto auto auto;
    gap: 0 24px;
    padding-inline-end: 24px;
    grid-auto-rows: min-content;
    max-height: calc(100vh - 442px);
    min-height: 40px;
  `,
  applyButton: css`
    margin-right: var(--cds-spacing-300);
  `,
  search: css`
    margin: var(--cds-spacing-300) 0;
    width: 61%;
    margin-top: 0;
  `,
  shorty: css`
    height: 180px;
  `,
};

const SearchFilterModal: React.FC<Props> = ({
  isModalOpen,
  setOpenModal,
  attribute,
  name,
  items,
  clearFilters,
  eventingData,
  setFiltersByFacet,
}) => {
  const { HeadingGroup, Content, Actions } = Dialog;
  const [searchValue, setSearchValue] = React.useState('');
  const [searchQuery, setSearchQuery] = React.useState('');
  const initialSelection = items.filter((item) => item.isRefined).map((item) => `${attribute}:${item.value}`);
  const [currentSelection, setCurrentSelection] = React.useState<string[]>(initialSelection);
  const onClear = React.useCallback<() => $TSFixMe>(() => setSearchQuery(''), []);
  const [displayedItems, setDisplayedItemsBasedOnUserInput] = React.useState(items);
  const track = useRetracked();
  const trackV3 = useTracker();

  const debouncedRefine = useDebounce(() => {
    setSearchQuery(searchValue);
    track({
      action: 'search',
      trackingName: 'filter_query_change',
      trackingData: {
        newSearchValue: removeWhiteSpace(searchValue),
        filterCategory: attribute,
        page: window?.location.pathname,
      },
    });
  }, 250);

  React.useEffect(() => {
    setDisplayedItemsBasedOnUserInput(
      items.filter((item) => item.label.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  }, [items, searchQuery]);
  return (
    <Dialog
      open={isModalOpen}
      variant="standard"
      width="large"
      onClose={() => setOpenModal(false)}
      aria-labelledby="checkbox-group"
    >
      <HeadingGroup>{name}</HeadingGroup>
      <Content>
        <Search
          fullWidth
          placeholder={_t('Search for #{filterName}', { filterName: name })}
          value={searchValue}
          onChange={(value) => {
            // live filtering
            debouncedRefine();
            setSearchValue(value);
          }}
          onSearch={setSearchQuery}
          onClear={onClear}
          css={styles.search}
        />
        <VisuallyHidden>
          <Typography2 component="h3" variant="subtitleMedium">
            {_t('Select options')}
          </Typography2>
        </VisuallyHidden>
        <div
          id="checkbox-group"
          aria-label={_t('Select #{filterName} options', { filterName: name })}
          css={[styles.modalCheckboxGroup, items.length < 13 && styles.shorty]}
        >
          <VisuallyHidden>
            <div aria-live="assertive" role="status" aria-atomic="true">
              {_t('Search has #{noOfResults} results', { noOfResults: displayedItems.length })}
            </div>
          </VisuallyHidden>
          {displayedItems.length === 0 && (
            <Typography2 component="p" variant="bodyPrimary" css={{ gridColumn: '1 / -1' }}>
              {_t('No results found')}
            </Typography2>
          )}
          {displayedItems.map((item) => (
            <SearchFilterItem
              key={item.label}
              {...item}
              isRefined={currentSelection.includes(`${attribute}:${item.value}`)}
              value={`${attribute}:${item.value}`}
              filterCategoryName={name}
              addFilters={(checkedFilter) => setCurrentSelection([...currentSelection, checkedFilter])}
              removeFilters={(uncheckedFilter) =>
                setCurrentSelection(currentSelection.filter((filter) => filter !== uncheckedFilter))
              }
              eventingData={eventingData}
            />
          ))}
        </div>
      </Content>
      <Actions>
        <Button
          variant="primary"
          onClick={() => {
            setFiltersByFacet?.(attribute, currentSelection);
            trackV3('apply_filter', {
              filter: currentSelection,
              filterSource: 'category_modal',
            });
            setOpenModal(false);
          }}
          css={styles.applyButton}
        >
          {_t('Apply')}
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setCurrentSelection([]);
            // for other components implementing SearchFilters and want to clear filters in a custom way
            if (setFiltersByFacet) {
              setFiltersByFacet(attribute, []);
            } else {
              clearFilters();
            }

            trackV3('apply_filter', {
              filter: [],
              filterSource: 'category_modal',
              isReset: true,
            });
          }}
        >
          {_t('Clear All')}
        </Button>
      </Actions>
    </Dialog>
  );
};

export default SearchFilterModal;
