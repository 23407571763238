/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import { Button, Dialog, SelectField, SelectOption } from '@coursera/cds-core';

import { useEoiEventing } from 'bundles/expression-of-interest/utils/eventingUtils';

import _t from 'i18n!nls/expression-of-interest';

type Props = {
  subject?: { categoryId: string; categoryText: string };
  handleSubjectChange: (selection: { categoryId: string; categoryText: string }) => void;
  handleSubmit: () => Promise<void | {}>;
  navigateConfirmation: () => void;
  trackingProductId: string;
};

const styles = {
  primaryButton: css`
    margin-right: var(--cds-spacing-200);
  `,
};

export const SelectSubjectSection = (props: Props): React.ReactElement => {
  const { subject, handleSubjectChange, handleSubmit, navigateConfirmation, trackingProductId } = props;
  const { trackSubmitEoi } = useEoiEventing(trackingProductId);
  const [subjectError, setSubjectError] = useState<string>('');

  const onNext = () => {
    navigateConfirmation();
  };

  const handleNext = () => {
    try {
      if (!subject) {
        setSubjectError(_t('Select a subject'));
        trackSubmitEoi('invalid_submission', 'select_subject_section');
      } else {
        handleSubmit();
        trackSubmitEoi('success', 'select_subject_section');
        onNext();
      }
    } catch {
      trackSubmitEoi('api_failure', 'select_subject_section');
    }
  };

  const subjects: Array<{ value: string; label: string }> = [
    { value: 'BUSINESS', label: _t('Business') },
    { value: 'COMPUTER_SCIENCE', label: _t('Computer Science') },
    { value: 'DATA_SCIENCE', label: _t('Data Science') },
    { value: 'HEALTHCARE', label: _t('Public Health') },
  ];

  const { HeadingGroup, Content, Actions } = Dialog;

  return (
    <>
      <HeadingGroup
        supportText={_t(
          'Selecting a subject area will allow us to share more specific information about degree programs that may interest you.'
        )}
      >
        {_t('Next, select a subject area')}
      </HeadingGroup>

      <Content>
        <SelectField
          label={_t('Subject')}
          fullWidth
          validationLabel={subjectError}
          validationStatus={subjectError ? 'error' : 'success'}
          onChange={(event) => {
            setSubjectError('');
            const categoryId = event.target.value as string;
            handleSubjectChange({
              categoryId,
              categoryText: subjects.find((s) => s.value === categoryId)?.label || '',
            });
          }}
        >
          {subjects.map((option) => (
            <SelectOption value={option.value} key={option.value}>
              {option.label}
            </SelectOption>
          ))}
        </SelectField>
      </Content>

      <Actions>
        <Button onClick={handleNext} css={styles.primaryButton}>
          {_t('Next')}
        </Button>
      </Actions>
    </>
  );
};

export default SelectSubjectSection;
