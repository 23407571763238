import phoneUtil from 'google-libphonenumber';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { SHORT_ANSWER_TYPE } from 'bundles/xddp/utils/name-formatting';

export type PhoneDetails = { phoneNumber?: string; countryCode?: string; extension?: string };

export const getFullPhoneNumber = (phone: PhoneDetails) => {
  if (phone?.phoneNumber?.startsWith('+')) {
    return phone?.phoneNumber;
  }

  const countryCode = phone?.countryCode || '';
  const phoneNumber = phone?.phoneNumber || '';
  const extension = phone?.extension || '';
  const fullPhoneNumber = '+' + countryCode + phoneNumber + extension;

  return fullPhoneNumber;
};

export const getPhoneNumberInput = (phone: PhoneDetails) => {
  return {
    phoneNumber: {
      [SHORT_ANSWER_TYPE]: {
        text: phone,
      },
    },
  };
};

export const getParsedNumber = (phoneNumber: string, regionCode = 'US') => {
  try {
    const phoneParser = phoneUtil.PhoneNumberUtil.getInstance();
    const parsedNumber = phoneParser.parseAndKeepRawInput(phoneNumber, regionCode);
    const isPossibleNumber = phoneParser.isPossibleNumber(parsedNumber);

    if (!isPossibleNumber) {
      return undefined;
    }

    return parsedNumber;
  } catch {
    // If phone input cannot be parsed because invalid format, return undefined
    return undefined;
  }
};

export const getPhoneDetails = (phoneNumber: string): undefined | { phone: PhoneDetails } => {
  const parsedNumber = getParsedNumber(phoneNumber);

  if (!parsedNumber) return parsedNumber;

  const nationalNumber = parsedNumber.getNationalNumber();
  const countryCode = parsedNumber.getCountryCode();
  const extension = parsedNumber.getExtension();

  const phone = {
    phoneNumber: nationalNumber ? nationalNumber.toString() : '',
    countryCode: countryCode ? countryCode.toString() : '',
    extension: extension ? extension.toString() : '',
  };

  return { phone };
};

export const getPhoneNumberFormat = (phoneNumber: string) => {
  const phoneDetails = getPhoneDetails(phoneNumber) || getPhoneDetails('+' + phoneNumber);
  const defaultPhoneNumberFormat = { phone: { phoneNumber } };

  const phoneNumberFormat = phoneDetails || defaultPhoneNumberFormat;

  return phoneNumberFormat;
};

export default { getFullPhoneNumber, getPhoneNumberInput, getParsedNumber, getPhoneDetails, getPhoneNumberFormat };
