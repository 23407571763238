/** @jsx jsx */
import { css } from '@emotion/react';

import type { CustomStyles, ValidPhone } from 'bundles/account-profile/components/i18n-phone-number-input/types';

/**
 * @deprecated This style is always applied, move this style to the InternationalPhoneNumberInput component
 */
export const customFormattedPhoneStyles: CustomStyles = {
  phoneNumberLabel: () => css`
    font-weight: 600;
    letter-spacing: -0.1px;
    margin-bottom: var(--cds-spacing-100);
  `,
  phoneNumberTextField: () => css`
    margin-top: calc(var(--cds-spacing-200) * -1);
  `,
  optionalText: css`
    font-style: italic;
    color: #636363;
    margin-left: 10px;
  `,
  phoneNumberInputContainer: css`
    flex-direction: column;
  `,
};

/**
 * @deprecated This formatter is unnecessary — we can unify the formatting directly within the
 * InternationalPhoneNumberInput component and standardize the handleFormChange prop naming accordingly.
 */
export const formChangeFormatter = (phone?: ValidPhone) => {
  const phoneNumberForEoiForm = { text: phone ? phone.phoneNumber.toString() : '' };
  return phoneNumberForEoiForm;
};
